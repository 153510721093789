/* eslint-disable import/prefer-default-export */
import { selectorFamily } from 'recoil'
import getCraftingService from 'services/crafting'

const craftingService = getCraftingService()

export const craftingRecipesSelector = selectorFamily({
  key: 'CraftingRecipesSelector',
  get: (page?: number) => async () => {
    try {
      const result = await craftingService.getRecipes(page, 6)
      return result || {
        recipes: [],
        total: 0,
        hasNextPage: false,
      }
    } catch (err) {
      return {
        recipes: [],
        total: 0,
        hasNextPage: false,
      }
    }
  },
})
