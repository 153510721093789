import { useContext, useMemo } from 'react'
import { CraftingRecipe } from 'domain/crafting-recipe.model'
import ThunderboltIcon from '../../assets/icons/thunderbolt.svg'
import { CraftingContext } from './crafting-context.component'
import useRecipeAvailability from './hooks/useRecipeAvailability.hook'

function RecipesLeft({ qty }: { qty: number }) {
  return <div className='recipes-left'>
    <div className='recipes-left__label' style={qty === 0 ? { backgroundColor: 'gray' } : {}}>{qty} remaining</div>
  </div>
}

export default function CraftingRecipeComponent({ recipe }: { recipe: CraftingRecipe }) {
  const { selectedRecipe, selectRecipe } = useContext(CraftingContext)
  const { available, loading: loadingLeft, checkAvailability } = useRecipeAvailability(recipe.id, [])
  const isSelected = useMemo(() => selectedRecipe?.id === recipe.id, [selectedRecipe, recipe])

  const handleSelect = () => {
    if (available > 0) {
      checkAvailability()
      selectRecipe(recipe)
    }
  }

  return <div className={`crafting-recipe ${isSelected ? 'selected-recipe' : ''} ${available === 0 ? 'recipe-unavailable' : ''}`} onClick={() => handleSelect()}>
    {!loadingLeft && <RecipesLeft qty={available} />}
    {available > 0 && <div
      className={`crafting-recipe__overlay ${isSelected ? 'show-overlay' : ''} ${available === 0 ? 'unavailable-overlay' : ''}`}>
      <img src={ThunderboltIcon} alt="Crafting Recipe" />
      <h1>Craft</h1>
    </div>}
    <img src={recipe.result.art} alt={recipe.result.name} />
  </div>
}
