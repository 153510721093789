import { League } from 'domain/league.type'
import moment from 'moment'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { allLeagues, selectLeague } from 'selectors/selectors'

export default function LeagueSelector() {
  const leagues = useRecoilValueLoadable(allLeagues)
  const [selectedLeague, setLeague] = useRecoilState(selectLeague)

  const handleChange = (e: any) => {
    setLeague(e.target.value)
  }

  return <select
    onChange={handleChange}
    value={selectedLeague}
    defaultValue={selectedLeague}
    style={{
      borderRadius: '5px',
      padding: '10px 15px',
      outlineColor: 'orange',
      background: '#c84a10',
      textShadow: '0 0 3px #000',
      border: 'none',
      color: 'white',
      fontWeight: 'bold',
    }}>
    {leagues.state === 'hasValue' && leagues.contents.map((league: League) => (
      <option key={league.id} value={league.id}>
        {league.name.toUpperCase()} - {moment(league.startDate).format('MMM YYYY').toUpperCase()}
      </option>
    ))}
  </select>
}
