import { SVGProps, useContext } from 'react'
import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { imxProviderInstance } from 'infraestructure/services/web3-provider/imx-provider.service'
import getCraftingService from 'services/crafting'
import { connectedWallet, profileBalancesSelector } from 'selectors/selectors'
// eslint-disable-next-line camelcase
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { ReactComponent as Holder } from './assets/holder.svg'
import { CraftingContext } from './crafting-context.component'
import { useAreAllIngredientsCompleted } from './hooks/useIsIngredientCompleted.hook'
import useCraftingCostSatisfied from './hooks/useCraftingCostSatisfied.hook'

const craftingService = getCraftingService()

export const HolderImage = (props: SVGProps<SVGSVGElement>) => <Holder {...props} />

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CraftButton({ recipe }: { recipe: CraftingRecipe }) {
  const {
    ingredientsAvailable,
    pickedNfts,
    setCraftingTransaction,
    setIsError,
    setIsLoading,
    isLoading,
  } = useContext(CraftingContext)
  const user = useRecoilValue(connectedWallet)
  const refreshBalances = useRecoilRefresher_UNSTABLE(profileBalancesSelector)

  if (!user) return <p>connect wallet</p>

  const handleCraft = async () => {
    if (isLoading) return
    try {
      setIsLoading(true)
      const assets = recipe.ingredients.map((ingredient) => {
        const available = ingredientsAvailable?.get(ingredient.id) || []
        return available.filter((asset) => pickedNfts.includes(asset.getId()))
      }).flat()
      if (!assets) return
      const transfers = await imxProviderInstance.batchNftTransfer(assets)
      const txIds = transfers.map((transfer) => transfer.txId)
      const result = await craftingService.craft(recipe.id, user?.address, txIds)
      setCraftingTransaction(result)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
      refreshBalances()
    }
  }

  return <div className='convert-button-wrapper' style={{ width: '100%' }}>
    {isLoading && <h2>CRAFTING IN PROCESS...</h2>}
    {!isLoading && <div
      onClick={() => handleCraft()}
      style={{ padding: '5px 35px', fontWeight: 'bold', fontSize: '1.4rem' }}
      className={'btn-convert btn btn-primary'}>CRAFT</div>}
  </div>
}

export default function CraftingTableFooter() {
  const { selectedRecipe } = useContext(CraftingContext)

  const areAllIngredientsSatisfied = useAreAllIngredientsCompleted(selectedRecipe?.ingredients || [])
  const isCraftingCostSatisfied = useCraftingCostSatisfied()
  const enableCraftButton = areAllIngredientsSatisfied
    && selectedRecipe !== undefined
    && isCraftingCostSatisfied

  return <div className='crafting-table-footer'>
    {enableCraftButton && <CraftButton recipe={selectedRecipe} />}
    {!enableCraftButton && <div style={{ padding: '5px 35px', fontWeight: 'bold', fontSize: '1.4rem' }}
      className={'btn-convert btn disabled'}>CRAFT</div>}
  </div>
}
