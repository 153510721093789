/* eslint-disable no-undef */
import { useEffect, useState } from 'react'
import getCraftingService from 'services/crafting'

const CraftingService = getCraftingService()

export default function useRecipeAvailability(recipeId: string, dependencies: any[]) {
  const [available, setAvailable] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: number }[]>([])

  const checkAvailability = async () => {
    try {
      setLoading(true)
      const recipeData = await CraftingService
        .checkAvailability([recipeId])
      setData(recipeData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    checkAvailability()
  }, dependencies)

  useEffect(() => {
    if (data.length > 0) {
      const recipe = data.find((recipeData) => recipeData[recipeId])
      if (recipe) {
        setAvailable(recipe[recipeId])
      }
    }
  }, [recipeId, data.length])

  return { available, loading, checkAvailability }
}
