import { useEffect, useState } from 'react'
import Axios from 'axios'
import Button from 'components/Button/Button.component'
import Wallet from 'domain/wallet.model'
import { ProfileDataType } from 'repositories/profile.repository'
import ga from 'react-ga'
import { profileSelector } from 'selectors/selectors'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { getSessionData, sessionExpired } from 'infraestructure/repositories/sessionVerifier.repository'
// eslint-disable-next-line camelcase
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import UserProfile from 'components/UserProfile/UserProfile.component'
import useConfirmDialog from 'hooks/confirm/useConfirmDialog.hook'
import { getHost } from '../../infraestructure/services/host-consumer/host-consumer.service'
import WelcomeModal from '../WelcomeModal/Welcome.modal'
import ProviderService from '../../infraestructure/services/web3-provider/imx-provider.service'

type ProfileBtnType = {
  className?: string,
  id?: string,
  children?: any,
  wallet: Wallet
}

export default function ProfileBtn({
  className,
  id,
  wallet,
}: ProfileBtnType) {
  const [signError, setSignError] = useState('')
  // const [firstLogin, setFirstLogin] = useState(false)
  const [signing, setSigning] = useState(false)
  const [checking, setChecking] = useState(false)
  const profile = useRecoilValueLoadable(profileSelector)

  const refreshProfile = useRecoilRefresher_UNSTABLE(profileSelector)
  const provider = new ProviderService()

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    verifySession()
  }, [wallet])

  const signMessage = async () => {
    setSignError('')
    try {
      setChecking(true)
      const response = await Axios.get(`${getHost()}/api/v1/auth?wallet=${wallet.address}`)
      setChecking(false)
      setSigning(true)
      const signature = await provider.sign(response.data.message)
      if (signature) {
        setSigning(false)
      }

      const r = await Axios.post(
        `${getHost()}/api/v1/auth`,
        {
          signature: signature.result,
          address: wallet.address,
        },
      )

      Axios.defaults.headers.common = { Authorization: `Bearer ${r.data.token}` }
      localStorage.setItem('session', JSON.stringify({ token: r.data.token, wallet: wallet.address }))
      setSigning(false)
      return true
    } catch (e: any) {
      setSigning(false)
      setSignError(e.message)
      setChecking(false)
      return false
    }
  }

  const verifySession = async () => {
    const session = getSessionData()

    if (session) {
      try {
        if (session.wallet !== wallet.address) {
          localStorage.removeItem('session')
          await signMessage()
          return
        }
        Axios.defaults.headers.common = { Authorization: `Bearer ${session.token}` }
        // const presponse = await ProfileRepository.getProfile(wallet)
        setSigning(false)

        ga.set({ userId: wallet.address })
        ga.set({ DeviantsId: wallet.address })

        refreshProfile()
      } catch (e) {
        sessionExpired()
      }
    } else {
      try {
        const isSigned = await signMessage()
        if (isSigned) {
          // const presponse = await ProfileRepository.getProfile(wallet)
          // if (presponse?.firstLogin) setFirstLogin(true)
        }
      } catch (e: any) {
        alert(e.message) // eslint-disable-line no-alert
        sessionExpired()
      }
      refreshProfile()
    }
  }

  const clearSessionData = () => {
    localStorage.removeItem('session')
    localStorage.removeItem('__terra_extension_router_session__')
    localStorage.removeItem('connectedWallet')
    window.location.reload() // TODO: quick solution, it shouldn't be here.
  }

  const { confirm } = useConfirmDialog({ text: 'Do you want to learn the basics in the tutorial?' })

  const handleWelcomeClosed = async () => {
    const confirmed = await confirm()
    if (confirmed) {
      window.location.href = '/tutorials'
    }
  }

  const getWelcome = () => {
    if (profile.state === 'hasValue') {
      if (!profile.contents?.info.conditionsAccepted) {
        return <WelcomeModal closeAction={() => {
          // setFirstLogin(false)
          refreshProfile()
          handleWelcomeClosed()
        }} />
      }
    }
    return <div></div>
  }

  const errorMessage = (nativeMsg: string) => {
    const mappedMessages: Record<string, string> = { 'Unable to open window': 'Please check your popup permissions' }
    const msg = mappedMessages[nativeMsg]
    if (msg !== undefined) { return msg }
    return nativeMsg
  }

  return (
    <div className={className} id={id}>
      <Modal isActive={signError !== ''} closeAction={() => { }} id="tryagain">
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <h2>Error while signing in wallet</h2>
          <br />
          <h4>{errorMessage(signError)}</h4>
          <h4>Wanna try again?</h4>
          <div>
            <br />
            <Button
              key={'no'}
              onClick={() => clearSessionData()}
              variant={'outline'}
              size={'medium'}>
              No
            </Button>
            <br />
            <Button
              key={'yes'}
              onClick={() => verifySession()}
              variant={'outline'}
              size={'medium'}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {profile.state === 'hasValue' && !signing && <UserProfile profile={profile.contents as ProfileDataType} disconnectClose={() => clearSessionData()} />}
      {profile.state === 'loading' && !signing && <>Loading profile..</>}
      {signing && <>Sign the message please...</>}
      {checking && <> Preparing signature...</>}

      {
        getWelcome()
      }
    </div>
  )
}
