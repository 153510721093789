import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { craftingRecipesSelector } from 'selectors/crafting'
import Pagination from 'components/rewards/pagination.component'
import CraftingRecipeComponent from './crafting-recipe.component'

export default function CraftingRecipes() {
  const [page, setPage] = useState(1)
  const recipes = useRecoilValueLoadable(craftingRecipesSelector(page))

  return <div id='crafing-recipes-container' className='recipes-container'>
    <h2 style={{ padding: '15px 0', textAlign: 'left', fontSize: '1.4rem' }}>RECIPES</h2>
    {(recipes.state === 'loading') ? <div>Loading...</div>
      : <>
        <div className='crafting-recipes scroll'>
          {recipes
            .contents?.recipes
            .map((recipe: CraftingRecipe) => <CraftingRecipeComponent key={recipe.id} recipe={recipe} />)}
        </div>
        <Pagination current={page} onChange={setPage} pageSize={6} total={recipes.contents.total / 6} />
      </>}
  </div>
}
